import splitbee from '@splitbee/web'

splitbee.init({
  disableCookie: true,
})

document.addEventListener('DOMContentLoaded', function () {
  const light = document.querySelector('#light')
  const dark = document.querySelector('#dark')

  light.addEventListener('click', function () {
    document.body.classList.remove('dark_mode')
    localStorage.setItem('theme', 'light')
  })
  dark.addEventListener('click', function () {
    document.body.classList.add('dark_mode')
    localStorage.setItem('theme', 'dark')
  })

  const prefersDarkScheme = window.matchMedia('(prefers-color-scheme: dark)')
  const currentTheme = localStorage.getItem('theme')

  if (currentTheme == 'dark') {
    document.body.classList.add('dark_mode')
  } else if (currentTheme == 'light') {
    document.body.classList.remove('dark_mode')
  } else {
    prefersDarkScheme.matches && document.body.classList.add('dark_mode')
  }
})
